import Head from 'next/head'
import FadeIn from 'react-fade-in'
import Body from 'components/Body'
import Icon from 'components/Icon'
import IconLayout from 'components/IconLayout'
import MainLayout from 'components/MainLayout'
import { FaGithub } from 'react-icons/fa'
import { SiTelegram, SiDiscord, SiQiwi } from 'react-icons/si'
import dynamic from 'next/dynamic'
const Time = dynamic(() => import('components/Time'), {
    ssr: false,
})

const Home = ({data}: any) => {
    return (
        <>
            <Head>
                <title>SkyWatcher_2019</title>
            </Head>
            <Body>
                <FadeIn>
                    <MainLayout />
                        <IconLayout>
                            <Icon icon={<FaGithub />} reference={'https://github.com/skywatcher2019'} copy={false} />
                            <Icon icon={<SiTelegram />} reference={'https://t.me/skywatcher_2019'} copy={false} />
                            <Icon icon={<SiDiscord />} reference={'SkyWatcher_2019#5464'} copy={true} />
                            <Icon icon={<SiQiwi />} reference={'https://qiwi.com/n/skywatcher2019'} copy={false} />
                        </IconLayout>
                    <Time />
                </FadeIn>
            </Body>
        </>
    )
}


export default Home
