import styles from 'styles/Home.module.scss'

const MainLayout = () => {
    return(
        <>
            <div className={styles.mainLayout}>
                <h1>SkyWatcher_2019</h1>
                <p>17-year-old Russian no-name developer and minecrafter.</p>
                <p>Developing Minecraft plugins, Discord bots, and learning website development.</p>
                <p>Time to write a new website.</p>
            </div>
        </>
    )
}

export default MainLayout
